import React from 'react'
import Layout from '../components/Layout'
export default function about() {
    return (
        <Layout pageTitle="About us">
            <div>
                <h1>About Page</h1>
                <p>Non quis eiusmod veniam excepteur do laborum laborum reprehenderit nisi aute.</p>
                <p>Non quis eiusmod veniam excepteur do laborum laborum reprehenderit nisi aute.</p>
                <p>Non quis eiusmod veniam excepteur do laborum laborum reprehenderit nisi aute.</p>
            </div>
        </Layout>
    )
}
